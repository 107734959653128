import React from "react";
import ContactForm from "./ContactForm";

const ContactUs = () => {
  return (
    <div className="relative min-h-screen w-screen rounded-lg shadow-lg overflow-hidden">
      <main>
        <ContactForm />
      </main>
    </div>
  );
};

export default ContactUs;
