import React from "react";

const Licences = () => {
  return (
    <section className="w-full py-16 bg-gradient-to-r from-yellow-50 to-pink-50">
      <h1
        className="text-3xl md:text-5xl font-extrabold text-center mb-12 text-orange"
      >
        Licences
      </h1>
      <div className="max-w-5xl mx-auto px-6 md:px-8 lg:px-12 bg-white bg-opacity-80 rounded-3xl shadow-2xl p-10 space-y-12 relative">
        <div className="space-y-6 text-gray-700">
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">1. Autorisation de Fonctionnement</h2>
            <p>
              Notre établissement, Jardin d'enfants Ghada, est autorisé à exercer ses activités par le ministère de la Femme, de la Famille et de l’Enfance. Cette autorisation nous permet d’offrir des services éducatifs et de garde aux enfants de 2,5 à 5 ans, conformément aux normes locales en matière de sécurité et d’éducation.
            </p>
            <p>
              <strong>Numéro d’autorisation :</strong> 2287 et 2289
              <br />
              <strong>Date de délivrance :</strong> 22 juillet 2009
            </p>
          </div>

          <div className="space-y-4">
            <h2 className="text-xl font-semibold">2. Conformité aux Normes Locales</h2>
            <p>
              Nous respectons toutes les réglementations locales applicables aux établissements de garde d'enfants, y compris les exigences en matière de sécurité incendie, de santé publique et d'hygiène. Nos installations sont régulièrement inspectées pour assurer la sécurité et le bien-être des enfants que nous accueillons.
            </p>
          </div>

          <div className="space-y-4">
            <h2 className="text-xl font-semibold">3. Formation et Certification du Personnel</h2>
            <p>
              Le personnel de Jardin d'enfants Ghada est composé de professionnels qualifiés qui ont suivi une formation certifiée en éducation de la petite enfance. Tous nos éducateurs et assistantes maternelles détiennent les certifications nécessaires pour travailler dans un environnement de garde d'enfants. Nous nous engageons à offrir un développement professionnel continu à notre équipe pour maintenir un haut niveau de compétence.
            </p>
          </div>

          <div className="space-y-4">
            <h2 className="text-xl font-semibold">4. Engagement envers la Qualité</h2>
            <p>
              Nous nous engageons à respecter les normes de qualité les plus élevées dans tous les aspects de notre service. Cela inclut le respect des ratios enfants/adultes, la mise en œuvre de programmes pédagogiques adaptés, et la création d'un environnement sécurisé et enrichissant pour tous les enfants sous notre responsabilité.
            </p>
          </div>

          <div className="space-y-4">
            <h2 className="text-xl font-semibold">5. Inspections et Audits</h2>
            <p>
              Notre établissement est soumis à des inspections régulières par les autorités compétentes pour garantir la conformité avec toutes les normes légales et réglementaires. Ces inspections couvrent la sécurité des installations, la qualité de l'environnement éducatif, et le respect des droits et du bien-être des enfants.
            </p>
          </div>

          <div className="space-y-4">
            <h2 className="text-xl font-semibold">6. Contactez-Nous</h2>
            <p>
              Pour toute question concernant nos licences ou pour obtenir des informations supplémentaires sur la conformité de notre établissement, veuillez nous contacter à :
            </p>
            <p>
              <strong>Adresse :</strong> 138 Av. Errached Sakiet Eddaier - SFAX
              <br />
              <strong>Email :</strong> jardindenfantsghada@gmail.com
              <br />
              <strong>Téléphone :</strong> +216 58 890 621 / +216 99 890 625
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Licences;
